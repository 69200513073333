const AppConsts = {
  localization: {
    defaultLocalizationSourceName: 'wellfare_api',
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
  },
  remoteServiceBaseUrlWelfare:
    window._env_.REACT_APP_REMOTE_SERVICE_BASE_URL_WELFARE,
  remoteServiceBaseUrlRomulo:
    window._env_.REACT_APP_REMOTE_SERVICE_BASE_URL_ROMULO,
  tokenExample: window._env_.TOKEN_EXAMPLE,
  domainSelectionForLocalhost: window._env_.DOMAIN_SELECTION_FOR_LOCALHOST,
  unleashClientKey: window._env_.UNLEASH_CLIENT_KEY,
  unleashApiUrl: window._env_.UNLEASH_API_URL,
  unleashEnv: window._env_.UNLEASH_ENV,
  mapboxToken: window._env_.MAPBOX_TOKEN,
  noosaAuthTokenSandbox: window._env_.NOOSA_AUTH_SANDBOX,
  noosaAuthTokenProd: window._env_.NOOSA_AUTH_PROD,
  noosaEnv: window._env_.NOOSA_ENV, // SANDBOX or PROD
  mixpanelProjectToken: window._env_.MIXPANEL_PROJECT_TOKEN,
};
export default AppConsts;
